<template>
   <div>
      <Notification ref="user_error_notification" type="error" message="Error en usuario y/o contraseña" />
      <Notification ref="expired_sesion" type="error" message="La sesion ha expirado" />
      <Notification ref="suscripcion" type="error" message="La suscripción ha finalizado..." />
      <div class="d-flex justify-center align-center" style="width: 100%; height: 100%">
         <v-card elevation="10" outlined width="90%" max-width="600px" :loading="isloading">
            <v-form class="pa-6">
               <v-text-field
                  v-model="username"
                  :error-messages="usernameErrors"
                  label="Usuario"
                  required
                  @input="$v.username.$touch()"
                  @blur="$v.username.$touch()"
               ></v-text-field>
               <v-text-field
                  v-model="password"
                  :error-messages="passwordErrors"
                  label="Contraseña"
                  required
                  @keydown.enter="submit"
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_password ? 'text' : 'password'"
                  @click:append="show_password = !show_password"
               ></v-text-field>
               <div class="d-flex justify-center align-center">
                  <v-btn color="primary" light class="mr-4" @click="submit" :loading="isloading">Iniciar sesión</v-btn>
                  <v-btn color="secondary" light @click="clear" x-small icon>
                     <v-icon dense>mdi-eraser</v-icon>
                  </v-btn>
               </div>
            </v-form>
         </v-card>
      </div>
   </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Notification from "../Notification.vue";

export default {
   mixins: [validationMixin],
   validations: {
      username: { required },
      password: { required },
   },
   components: {
      Notification,
   },
   data: () => ({
      username: "",
      password: "",
      show_password: false,
      isloading: false,
   }),
   computed: {
      usernameErrors() {
         const errors = [];
         if (!this.$v.username.$dirty) return errors;
         !this.$v.username.required && errors.push("El nombre de usuario es requerido");
         return errors;
      },
      passwordErrors() {
         const errors = [];
         if (!this.$v.password.$dirty) return errors;
         !this.$v.password.required && errors.push("La contraseña es requerida");
         return errors;
      },
   },
   methods: {
      async submit() {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = await this.$store.dispatch("login", {
                  username: this.username,
                  password: this.password,
               });
               if (res == true) {
                  this.$router.push({
                     name: "Dashboard",
                     query: {
                        status: "welcome",
                        time: new Date().getTime(),
                     },
                  });
               } else {
                  throw res;
               }
            } else {
               throw "error en formulario";
            }
         } catch (error) {
            if (error.response.status) {
               switch (error.response.status) {
                  case 401:
                     this.$refs.suscripcion.Show();
                     break;
                  default:
                     this.$refs.user_error_notification.ShowPersistent(error);
                     break;
               }
            }
            this.clear();
         } finally {
            this.isloading = false;
         }
      },
      clear() {
         this.$v.$reset();
         this.username = "";
         this.password = "";
      },
   },
   mounted: function () {
      if (this.$router.currentRoute.query.status == "expired") {
         this.$refs.expired_sesion.Show();
      }
   },
   beforeRouteUpdate(to, from, next) {
      if (to.query.status == "expired") {
         this.$refs.expired_sesion.Show();
      }
      next();
   },
};
</script>
